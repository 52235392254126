<template>
  <div id="map-container" style="width: 80%; height: 500px; margin: 0 auto;"></div>
</template>

<script>
export default {
  name: 'BaiduMap',
  mounted() {
    window.onload = () => {
		let winMap = window.BMapGL
      // 初始化地图
      const map = new winMap.Map('map-container');
      // 设置中心点坐标
      const point = new winMap.Point(118.8, 31.9625);
      // 初始化地图，设置中心点和缩放级别
      map.centerAndZoom(point, 15);
      // 添加地图控件
      map.addControl(new winMap.NavigationControl());
	  
	  // 创建一个标记
	  const marker = new winMap.Marker(point);
	  // 将标记添加到地图上
	  map.addOverlay(marker);
	  
	  // 定义标记的名称
	          const markerName = '南京苏欧机电设备有限公司<br>江苏省南京市江宁区金源路绿地之窗G1栋1603-1605';
	          // 创建信息窗口
	          const infoWindow = new winMap.InfoWindow(markerName);
	  
	          // 当标记被点击时，打开信息窗口
	          map.openInfoWindow(infoWindow, point);
			  
			  // 为标记添加点击事件监听器
			          marker.addEventListener('click', function () {
			              // 当标记被点击时，打开信息窗口
			              map.openInfoWindow(infoWindow, point);
			          });
    };
  }
}
</script>